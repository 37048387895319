import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import { FormHint } from 'bloko/blocks/form';
import BlokoLink from 'bloko/blocks/link';
import { format } from 'bloko/common/trl';

import { BLACKLIST, NO_ONE, WHITELIST } from 'lux/models/resume/resumeAccess.types';
import translation from 'src/components/translation';
import { arrayToHumanReadableList } from 'src/utils/humanReadableList';

const getEmployersList = (employers, andTrl) => {
    const employersNames = employers.map((employer) => `«${employer.name}»`);
    return arrayToHumanReadableList(employersNames, andTrl);
};

const VisibilityHint = ({ resume, visibility, vacancy, hidden, trls }) => {
    if (!visibility || hidden) {
        return null;
    }

    const hash = resume.hash;

    let message;
    if (visibility.accessType === NO_ONE) {
        if (visibility.whitelist.containsEmployer) {
            // «Не видно никому» меняется на «Только перечисленным компаниям»,
            // компания уже есть в белом списке (лимит в этом случае не важен)
            message = format(trls[VisibilityHint.trls.noOne.employerToWhitelist], {
                '{0}': vacancy.company.visibleName,
            });
        } else if (!visibility.whitelist.containsEmployer && visibility.whitelist.canAdd <= 0) {
            // «Не видно никому» должно поменяться на «Только перечисленным компаниям»
            // с добавлением компании в белый список, но список заполнен
            message = trls[VisibilityHint.trls.noOne.whitelistIsFull];
        } else {
            // «Не видно никому» меняется на «Только перечисленным компаниям», компания добавляется в белый список
            message = format(trls[VisibilityHint.trls.noOne.toWhitelist], {
                '{0}': vacancy.company.visibleName,
            });
        }
    }
    if (visibility.accessType === WHITELIST && !visibility.whitelist.containsEmployer) {
        if (visibility.whitelist.canAdd <= 0) {
            // Резюме видно только перечисленным компаниям,
            // компания должна добавиться в белый список, но список заполнен
            // (canAdd может быть отрицательным, если лимит превышен)
            message = format(trls[VisibilityHint.trls.whitelist.whitelistIsFull], {
                '{0}': visibility.whitelist.limit,
            });
        } else {
            // Резюме видно только перечисленным компаниям,
            // компании нет в белом списке, при отклике она будет добавлена
            message = format(trls[VisibilityHint.trls.whitelist.addEmployer], {
                '{0}': vacancy.company.visibleName,
            });
        }
    }
    if (visibility.accessType === BLACKLIST && visibility.connectedEmployers.length) {
        if (visibility.connectedEmployers.length === 1) {
            // Резюме видно всем компаниям, кроме перечисленных,
            // только текущая компания в черном списке пользователя и будет удалена из него
            message = format(trls[VisibilityHint.trls.blacklist.removeEmployerCurrent], {
                '{0}': vacancy.company.visibleName,
            });
        } else {
            // Резюме видно всем компаниям, кроме перечисленных,
            // одна или несколько компаний, в которых работает менеджер этой вакансии
            // есть в чёрном списке и будет удалена из него
            message = format(trls[VisibilityHint.trls.blacklist.removeEmployerMany], {
                '{0}': vacancy.name,
                '{1}': getEmployersList(visibility.connectedEmployers, trls[VisibilityHint.trls.and]),
            });
        }
    }
    if (!message) {
        return null;
    }
    return (
        <div className="vacancy-response-popup-resume__hint">
            <FormHint>
                {message}{' '}
                <BlokoLink
                    disableVisited
                    Element={Link}
                    to={`/applicant/resumes/edit/visibility?resume=${hash}`}
                    target="_blank"
                >
                    {trls[VisibilityHint.trls.changeVisibility]}
                </BlokoLink>
            </FormHint>
        </div>
    );
};

VisibilityHint.trls = {
    noOne: {
        toWhitelist: 'vacancy.response.popup.visibility.noOne',
        employerToWhitelist: 'vacancy.response.popup.visibility.noOne.companyInWhiteList',
        whitelistIsFull: 'vacancy.response.popup.visibility.noOne.responseDisabled',
    },
    whitelist: {
        addEmployer: 'vacancy.response.popup.visibility.addToWhiteList',
        whitelistIsFull: 'vacancy.response.popup.visibility.limitExceeded',
    },
    blacklist: {
        removeEmployerMany: 'vacancy.response.popup.visibility.removeFromBlackList.many',
        removeEmployerCurrent: 'vacancy.response.popup.visibility.removeFromBlackList',
    },
    changeVisibility: 'vacancy.response.popup.visibility.changeVisibility',
    and: 'abstract.and',
};

VisibilityHint.propTypes = {
    resume: PropTypes.object,
    visibility: PropTypes.object,
    vacancy: PropTypes.object,
    hidden: PropTypes.bool,
    trls: PropTypes.object,
};

export default translation(VisibilityHint);
