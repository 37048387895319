import classnames from 'classnames';

import {
    Avatar,
    Cell,
    CellText,
    Link as MagritteLink,
    Radio as MagritteRadio,
    VSpacing,
    HSpacing,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { FormHint } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import BlokoLink from 'bloko/blocks/link';
import Radio from 'bloko/blocks/radio';
import Text, { TextSize } from 'bloko/blocks/text';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Debug from 'HHC/Debug';
import { type ResponseStatusResume } from 'lux/models/applicantVacancyResponseStatuses';
import { type ResumeVisibility } from 'lux/models/vacancyResponsePopup';
import { type VacancySearchItem } from 'lux/models/vacancySearch/vacancySearchItem.types';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';
import { ensureKnownGender } from 'src/utils/resume/gender';

import ResumeHint from 'src/components/VacancyResponseForm/ResumeHint';
import VisibilityHint from 'src/components/VacancyResponseForm/VisibilityHint';

import styles from './resume.less';

interface ResumeProps {
    resume: ResponseStatusResume;
    selected: boolean;
    single: boolean;
    hidden: boolean;
    onSelect: (resume: ResponseStatusResume) => void;
    visibility: ResumeVisibility;
    vacancy: VacancySearchItem;
}

const TrlKeys = {
    resumeLink: {
        view: 'vacancy.response.popup.resume.link',
        toSettings: 'vacancy.response.popup.resume.settings',
    },
    incompleteHint: 'vacancy.response.popup.unfinished.hint',
    emptyTitle: 'resume.title.empty',
};

const Resume: TranslatedComponent<ResumeProps> = ({
    resume,
    selected,
    single,
    hidden,
    onSelect,
    visibility,
    vacancy,
    trls,
}) => {
    if (!resume.title?.[0]?.string) {
        Debug.log('out error', new Error('Empty resume title'), {
            resume: {
                title: resume.title,
                titleFirst: resume.title?.[0],
                titleString: resume.title?.[0]?.string,
                accessType: resume.accessType,
                isIncomplete: resume.isIncomplete,
                ...resume._attributes,
            },
        });
    }
    const { hash, isIncomplete } = resume;
    // тайтла может не быть, если это незавершенное дублированное резюме
    const name = resume.title[0]?.string ?? trls[TrlKeys.emptyTitle];

    const isMagritte = useExperiment('magritte_on_vacancy_response');
    const { isMobile } = useBreakpoint();

    if (isMagritte) {
        const link = hidden ? (
            <MagritteLink
                Element={Link}
                to={`/applicant/resumes/edit/visibility?resume=${hash}`}
                target="_blank"
                typography={!isMobile ? 'label-2-regular' : 'label-3-regular'}
            >
                {trls[TrlKeys.resumeLink.toSettings]}
            </MagritteLink>
        ) : (
            <MagritteLink
                Element={Link}
                to={`/resume/${hash}`}
                target="_blank"
                data-qa="vacancy-response-view-resume"
                typography={!isMobile ? 'label-2-regular' : 'label-3-regular'}
            >
                {trls[TrlKeys.resumeLink.view]}
            </MagritteLink>
        );

        return (
            <>
                <Cell
                    left={
                        single ? undefined : (
                            <MagritteRadio
                                id={`resume_${hash}`}
                                name="resumeId"
                                value={resume.hash}
                                checked={selected}
                                disabled={hidden}
                                onChange={() => {
                                    onSelect(resume);
                                }}
                            />
                        )
                    }
                    avatar={
                        <Avatar
                            mode="image"
                            aria-label={resume.fio}
                            size={48}
                            image={resume.photoUrls[0]?.preview}
                            fallbackMode="placeholder"
                            placeholder={ensureKnownGender(resume.gender[0]?.string)}
                        />
                    }
                    right={!isMobile ? <div className={styles.linkPadding}>{link}</div> : undefined}
                    vertPadding={!single}
                >
                    <CellText type="title" maxLines={1} data-qa="resume-title">
                        {name}
                    </CellText>
                    <CellText type="subtitle" maxLines={1}>
                        {resume.area[0]?.title}
                        {!!(resume.area[0]?.title && resume.salary[0]) &&
                            [NON_BREAKING_SPACE, '•', NON_BREAKING_SPACE].join('')}
                        {!!resume.salary[0] && (
                            <FormatMoney currency={resume.salary[0].currency}>{resume.salary[0].amount}</FormatMoney>
                        )}
                    </CellText>
                </Cell>
                {isMobile && (
                    <>
                        {single && <VSpacing default={16} />}
                        <Cell left={!single ? <HSpacing default={24} /> : undefined}>{link}</Cell>
                        {!single && <VSpacing default={16} />}
                    </>
                )}
                <ResumeHint
                    resume={resume}
                    visibility={visibility}
                    vacancy={vacancy}
                    hidden={hidden}
                    incomplete={isIncomplete}
                    visible={selected}
                    single={single}
                />
            </>
        );
    }

    const input = single ? (
        <span data-qa="resume-title">{name}</span>
    ) : (
        <Radio
            checked={selected}
            onChange={() => onSelect(resume)}
            id={`resume_${hash}`}
            labelProps={{ 'data-qa': 'resume-title' }}
            disabled={hidden}
        >
            {name}
        </Radio>
    );

    const link = hidden ? (
        <BlokoLink
            disableVisited
            Element={Link}
            to={`/applicant/resumes/edit/visibility?resume=${hash}`}
            target="_blank"
        >
            {trls[TrlKeys.resumeLink.toSettings]}
        </BlokoLink>
    ) : (
        <BlokoLink
            disableVisited
            Element={Link}
            to={`/resume/${hash}`}
            target="_blank"
            className="vacancy-response-popup-resume__link"
            data-qa="vacancy-response-view-resume"
        >
            <Text Element="span" size={TextSize.Small}>
                {trls[TrlKeys.resumeLink.view]}
            </Text>
        </BlokoLink>
    );

    const incompleteHint = !!isIncomplete && (
        <div className="vacancy-response-popup-resume__hint">
            <FormHint>{trls[TrlKeys.incompleteHint]}</FormHint>
        </div>
    );

    return (
        <div
            className={classnames('vacancy-response-popup-resume', {
                'vacancy-response-popup-resume_selected': selected,
            })}
        >
            <div className="vacancy-response-popup-resume__title">
                <Gap right>{input}</Gap>
                {link}
            </div>
            <VisibilityHint resume={resume} visibility={visibility} vacancy={vacancy} hidden={hidden} />
            {incompleteHint}
        </div>
    );
};

export default translation(Resume);
